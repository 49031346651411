import React, { useCallback, useContext, useState, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import {
  SEO,
  ButtonPrimary,
  EmailInput,
  ButtonGoogle,
  PasswordInput,
  ButtonText,
  ButtonOutlined,
} from '@components';
import { LayoutRegistration } from '@components/Structural/PerfilInversion';
import {
  Route,
  RegisterForm,
  ChangeEvent,
  ExistenceValidationType,
} from '@interfaces';
import {
  AuthContext,
  LoadingContext,
  SnackbarContext,
  InvestmentsContext,
} from '@context';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { navigate } from '@reach/router';
import { useAuth } from '@hooks';
import { GoogleIcon } from '@images';
import { size } from 'lodash';
import { APPLE_ICON } from 'images/GCP/registrationMailing';

interface LoginForm {
  email: RegisterForm['email'];
  password: RegisterForm['password'];
}

const SaveByRokinPage: React.FC<PageProps> = () => {
  const classes = useStyles();
  const { isLoggedIn } = useAuth();
  const { hasInvestments } = useContext(InvestmentsContext);
  useEffect(() => {
    if (isLoggedIn) {
      navigate(Route.finance);
    }
  }, [isLoggedIn]);
  const [wrongLogin, setWrongLogin] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formLogin, setFormLogin] = useState<LoginForm>({
    email: '',
    password: '',
  });

  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { loginWithEmailPassword, loginWithGoogle /* setTempAccToken */ } =
    useContext(AuthContext);

  const handleChange = (event: ChangeEvent) => {
    const name = event.target.name as keyof LoginForm;
    const value = event.target.value;
    setFormLogin((prev) => ({ ...prev, [name]: value }));
  };

  const changeEmail = useCallback(
    (e: ChangeEvent) => {
      handleChange(e);
      setWrongLogin(false);
    },
    [handleChange, setWrongLogin]
  );

  const onEmailErrorChange = useCallback(
    (error: boolean) => setEmailError(error),
    [setEmailError]
  );

  const changePassword = useCallback(
    (e: ChangeEvent) => {
      handleChange(e);
      setWrongLogin(false);
    },
    [handleChange, setWrongLogin]
  );

  const disabledContinueButton =
    !formLogin.email || !formLogin.password || emailError || wrongLogin;

  const clickContinue = useCallback(() => {
    // console.log("Email", formLogin.email);
    // console.log("Password", formLogin.password);
    showLoader('log in');
    loginWithEmailPassword({
      email: formLogin.email,
      password: formLogin.password,
      onSuccess: () => {
        hideLoader('log in');
        navigate(Route.finance);
      },
      onFailure: () => {
        setWrongLogin(true);
        hideLoader('log in');
      },
    });
  }, [formLogin, setWrongLogin]);

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !disabledContinueButton) clickContinue();
    },
    [disabledContinueButton, clickContinue]
  );

  const passwordResetEmail = useCallback(() => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, formLogin.email)
      .then(() => {
        showSnackbar('Te enviamos un correo para reestablecerla', 'success');
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
        showSnackbar('Algo salió mal', 'error');
      });
  }, [formLogin.email]);

  return (
    <>
      <SEO />
      <LayoutRegistration>
        <section className={classes.container}>
          <div className={classes.title}>
            Bienvenido a Rokin
            <span>
              Entra para acceder a tus beneficios y mejorar tu salud financiera
            </span>
          </div>
          <div className={classes.form}>
            <div>
              <ButtonGoogle
                onClick={loginWithGoogle}
                text="Entrar con Google"
              />
              <ButtonOutlined
                className={'className'}
                text={'Entrar con Apple'}
                startIcon={
                  <>
                    <img src={APPLE_ICON} alt="" />
                  </>
                }
                onClick={()=> {loginWithGoogle(undefined, true)} } ///HANDLE APPLE LOGIN HERE
              />
              <div className={classes.divisionForm}>
                <div className={classes.linea}></div>O
                <div className={classes.linea}></div>
              </div>
              <EmailInput
                name="email"
                error={wrongLogin}
                helperText={
                  wrongLogin ? 'Usuario o contraseña no válido' : undefined
                }
                value={formLogin.email}
                onChange={changeEmail}
                onErrorChange={onEmailErrorChange}
                validateExistence={ExistenceValidationType.MUST_EXISTS}
              />
              <PasswordInput
                name="password"
                value={formLogin.password}
                onChange={changePassword}
                onKeyPress={onKeyPress}
              />
              <ButtonText
                className={classes.passRecovery}
                text="Olvidé mi contraseña"
                onClick={passwordResetEmail}
                disabled={!formLogin.email || emailError}
              />
              <ButtonPrimary
                className={classes.login}
                text="Iniciar sesión"
                disabled={disabledContinueButton}
                onClick={clickContinue}
                //onClick={() => navigate(Route.onBoard)}
              />
              <ButtonText
                className={classes.goRegister}
                text="Crear una cuenta"
                onClick={() => navigate(Route.register)}
              />
            </div>
          </div>
        </section>
      </LayoutRegistration>
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '45% 46%',
    height: 650,
    [theme.breakpoints.down(1100)]: {
      gridTemplateColumns: '30% 70%',
    },
    [theme.breakpoints.down(895)]: {
      gridTemplateColumns: '80%',
      justifyContent: 'center',
      height: '50rem',
    },
  },
  login: {
    marginBottom: '1rem',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '3rem',
    fontWeight: 'bold',
    width: 288,
    paddingLeft: '3rem',
    color: '#393e44',
    [theme.breakpoints.down(895)]: {
      textAlign: 'center',
      margin: '0 auto',
      padding: 0,
    },
    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      fontWeight: 'normal',
      display: 'block',
      color: theme.palette.primary.main,
      width: 255,
    },
  },
  form: {
    paddingLeft: '9rem',
    [theme.breakpoints.down(895)]: {
      padding: 0,
    },
    '& >div': {
      display: 'grid',
      gap: 27,
      width: 344,
      [theme.breakpoints.down(895)]: {
        width: 300,
        margin: '0 auto',
      },
      '& >button:first-child': {
        [theme.breakpoints.down(895)]: {
          marginTop: '4rem',
        },
      },
      '&>div': {
        [theme.breakpoints.down(895)]: {
          width: 300,
        },
      },
    },
  },
  divisionForm: {
    display: 'flex',
    color: '#9d9d9d',
    alignItems: 'center',
  },
  linea: {
    width: 190.5,
    height: 2,
    flexGrow: 0,
    backgroundColor: '#9d9d9d',
    margin: 14,
  },
  terminos: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    display: 'block',
    color: '#545d69',
    gap: 6,
    textAlign: 'center',
  },
  boton2: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    fontWeight: 'normal',
    display: 'block',
    color: '#545d69',
  },
  passCheck: {
    display: 'grid',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
    },
  },
  passRecovery: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12.8,
    margin: '-18px 0 -18px 0',
    fontWeight: 'normal',
    display: 'block',
    color: '#545d69',
  },
  goRegister: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.8rem',
    marginTop: -30,
    fontWeight: 'normal',
    display: 'block',
    color: '#545d69',
    [theme.breakpoints.down(895)]: {
      paddingBottom: '3rem',
    },
  },
}));
